import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import {
  AssetPropertyNoteListResponse,
  AssetPropertyNote,
  AssetPropertyNoteResponse
} from "../../types/accelerator";
import { escapeCharacters } from "../../utils";
import { SearchApiParams } from "../../types";

/**
 *  Get AssetPropertyNote
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetPropertyNoteListResponse>}
 */
const searchAssetPropertyNotes = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetPropertyNote>): Promise<AssetPropertyNoteListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || [
      {
        "asset.assetCode.keyword": { order: "asc" }
      },
      {
        "assetProperty.name.keyword": { order: "asc" }
      }
    ]
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("assetPropertyAnnotations/search", searchBody);
};

/**
 * Get AssetPropertyNote By ID
 *
 * @param AssetPropertyNoteId: @ String
 *
 * @return {Promise<AssetPropertyNoteResponse>}
 */
export const getAssetPropertyNoteById = async (
  AssetPropertyNoteId: string
): Promise<AssetPropertyNoteResponse> => {
  return acceleratorApi.get<AssetPropertyNoteResponse>(
    `assetPropertyAnnotations/${AssetPropertyNoteId}`
  );
};

/**
 * Create AssetPropertyNote
 *
 * @param AssetPropertyNote: @type Partial<AssetPropertyNote>
 *
 * @return {Promise<AssetPropertyNoteResponse>}
 */
export const createAssetPropertyNote = async (
  AssetPropertyNote: Partial<AssetPropertyNote>
): Promise<AssetPropertyNoteResponse> => {
  return acceleratorApi.post<Partial<AssetPropertyNote>, AssetPropertyNoteResponse>(
    "assetPropertyAnnotations",
    AssetPropertyNote
  );
};

/**
 * Update Note
 * @param note: @type AssetPropertyNote
 *
 * @return {Promise<AssetPropertyNoteResponse>}
 */
export const updateAssetPropertyNote = async (
  note: AssetPropertyNote
): Promise<AssetPropertyNoteResponse> => {
  return acceleratorApi.put(`assetPropertyAnnotations/${note.id}`, note);
};

/**
 * Delete AssetPropertyNote
 *
 * @param AssetPropertyNoteId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteAssetPropertyNote = async (assetPropertyNoteId: string): Promise<void> => {
  await acceleratorApi.delete(`assetPropertyAnnotations/${assetPropertyNoteId}`);
};

export const AssetPropertyNotesApi = {
  search: searchAssetPropertyNotes,
  getById: getAssetPropertyNoteById,
  create: createAssetPropertyNote,
  update: updateAssetPropertyNote,
  delete: deleteAssetPropertyNote
};
