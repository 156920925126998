import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { acceleratorApi } from ".";
import {
  AssetNoteListResponse,
  AssetNote,
  AssetNoteResponse,
  AssetAnnotationSourceListResponse,
  AssetAnnotationActionListResponse,
  AssetAnnotationReliabilityCategoryListResponse,
  AssetAnnotationImpactListResponse,
  AssetAnnotationPlantStatusListResponse,
  AssetNoteAttachment,
  AssetNoteAttachmentListResponse,
  AssetNoteAttachmentResponse
} from "../../types/accelerator";
import { escapeCharacters, urlPathWithQueryParams } from "../../utils";
import { CreateAttachmentRequest, SearchApiParams } from "../../types";
import { createAttachment } from "../shared";
/**
 *  Get AssetNote
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AssetNoteListResponse>}
 */
const searchAssetNotes = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetNote>): Promise<AssetNoteListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || [
      {
        createdOn: { order: "desc" }
      },
      {
        "asset.assetCode.keyword": { order: "asc" }
      }
    ]
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    const { createdOn, endDate, ...rest } = filter;
    if (createdOn) {
      (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
        range: {
          createdOn: {
            gte: `${createdOn}T00:00:00`,
            lte: `${endDate || createdOn}T23:59:59`,
            format: "yyyy-MM-dd'T'HH:mm:ss"
          }
        }
      });
    }

    Object.entries(rest).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}${key === "asset.siteId" ? "" : ".id"}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("assetAnnotations/search", searchBody);
};

/**
 * Get AssetNote By ID
 *
 * @param AssetNoteId: @ String
 *
 * @return {Promise<AssetNoteResponse>}
 */
export const getAssetNoteById = async (AssetNoteId: string): Promise<AssetNoteResponse> => {
  return acceleratorApi.get<AssetNoteResponse>(`assetAnnotations/${AssetNoteId}`);
};

/**
 * Get AssetNote By ID
 *
 * @param assetNoteId: @ String
 *
 * @return {Promise<AssetNoteListResponse>}
 */
export const searchAssetNoteById = async (assetNoteId: string): Promise<AssetNoteListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: assetNoteId }
          }
        ]
      }
    }
  };
  return await acceleratorApi.post("assetAnnotations/search", searchBody);
};

/**
 * Create AssetNote
 *
 * @param AssetNote: @type Partial<AssetNote>
 *
 * @return {Promise<AssetNoteResponse>}
 */
export const createAssetNote = async (
  AssetNote: Partial<AssetNote>
): Promise<AssetNoteResponse> => {
  return acceleratorApi.post<Partial<AssetNote>, AssetNoteResponse>("assetAnnotations", AssetNote);
};

/**
 * Update Note
 * @param note: @type AssetNote
 *
 * @return {Promise<AssetNoteResponse>}
 */
export const updateAssetNote = async (note: AssetNote): Promise<AssetNoteResponse> => {
  return acceleratorApi.put(`assetAnnotations/${note.id}`, note);
};

/**
 * Delete AssetNote
 *
 * @param AssetNoteId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteAssetNote = async (AssetNoteId: string): Promise<void> => {
  await acceleratorApi.delete(`assetAnnotations/${AssetNoteId}`);
};

/**
 * Get AssetAnnotationSource
 *
 * @return {Promise<AssetAnnotationSourceListResponse>}
 */
export const getAssetAnnotationSource = async (): Promise<AssetAnnotationSourceListResponse> => {
  return await acceleratorApi.get(
    urlPathWithQueryParams(`assetAnnotationSources`, { page: 0, size: 1000 })
  );
};

/**
 * Get AssetAnnotationAction
 *
 * @return {Promise<AssetAnnotationActionListResponse>}
 */
export const getAssetAnnotationAction = async (): Promise<AssetAnnotationActionListResponse> => {
  return await acceleratorApi.get(
    urlPathWithQueryParams(`assetAnnotationActions`, { page: 0, size: 1000 })
  );
};

/**
 * Get AssetAnnotationImpact
 *
 * @return {Promise<AssetAnnotationImpactListResponse>}
 */
export const getAssetAnnotationImpact = async (): Promise<AssetAnnotationImpactListResponse> => {
  return await acceleratorApi.get(
    urlPathWithQueryParams(`assetAnnotationImpacts`, { page: 0, size: 1000 })
  );
};

/**
 * Get AssetAnnotationReliabilityCategory
 *
 * @return {Promise<AssetAnnotationReliabilityCategoryListResponse>}
 */
export const getAssetAnnotationReliabilityCategory =
  async (): Promise<AssetAnnotationReliabilityCategoryListResponse> => {
    return await acceleratorApi.get(
      urlPathWithQueryParams(`assetAnnotationReliabilityCategories`, { page: 0, size: 1000 })
    );
  };

/**
 * Get AssetAnnotationPlantStatus
 *
 * @return {Promise<AssetAnnotationPlantStatusListResponse>}
 */
export const getAssetAnnotationPlantStatus =
  async (): Promise<AssetAnnotationPlantStatusListResponse> => {
    return await acceleratorApi.get(
      urlPathWithQueryParams(`assetAnnotationPlantStatuses`, { page: 0, size: 1000 })
    );
  };

/**
 * Get AssetNote Attachment by Id
 *
 * @param assetNoteAttachmentId: @type String
 * @param assetNoteId: @type String
 *
 * @return {Promise<AssetNoteAttachmentResponse>}
 */
export const getAssetNoteAttachmentById = async ({
  assetNoteId,
  assetNoteAttachmentId
}: {
  assetNoteId: string;
  assetNoteAttachmentId: string;
}): Promise<AssetNoteAttachmentResponse> => {
  return await acceleratorApi.get<AssetNoteAttachmentResponse>(
    `assetAnnotations/${assetNoteId}/attachments/${assetNoteAttachmentId}`
  );
};

/**
 * Get AssetNote Attachments
 *
 * @param assetNoteId: @type String
 *
 * @return {Promise<AssetNoteAttachmentListResponse>}
 */
export const getAssetNoteAttachments = async ({
  assetNoteId
}: {
  assetNoteId: string;
}): Promise<AssetNoteAttachmentListResponse> => {
  return await acceleratorApi.get<AssetNoteAttachmentListResponse>(
    `assetAnnotations/${assetNoteId}/attachments`
  );
};

/**
 * Create Asset Note Attachment
 *
 * @param assetNoteId: @type String
 *
 * @return {Promise<AssetNoteAttachmentListResponse>}
 */
export const createAssetNoteAttachment = async ({
  request
}: {
  assetNoteId: string;
  request: CreateAttachmentRequest;
}): Promise<AssetNoteAttachment> => {
  const response = await createAttachment({
    apiInstance: acceleratorApi,
    endpointUrl: `assetAnnotationAttachments/bulk`,
    request
  });

  return response as AssetNoteAttachment;
};

/**
 * Delete Asset Note Attachment
 *
 * @param assetNoteAttachmentId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteAssetNoteAttachment = async (assetNoteAttachmentId: string): Promise<void> => {
  await acceleratorApi.delete(`assetAnnotationAttachments/${assetNoteAttachmentId}`);
};

export const AssetNotesApi = {
  search: searchAssetNotes,
  getById: getAssetNoteById,
  create: createAssetNote,
  update: updateAssetNote,
  delete: deleteAssetNote,
  getAssetAnnotationSource,
  getAssetAnnotationAction,
  getAssetAnnotationImpact,
  getAssetAnnotationReliabilityCategory,
  getAssetAnnotationPlantStatus,
  getAssetNoteAttachments,
  getAssetNoteAttachmentById,
  createAssetNoteAttachment,
  searchAssetNoteById,
  deleteAssetNoteAttachment
};
