import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { Operation, compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import {
  AlarmThreshold,
  AlarmThresholdListResponse,
  AlarmThresholdDocumentResponse
} from "../../types/accelerator";
import { escapeCharacters } from "../../utils";
import { SearchApiParams } from "../../types";

/**
 *  Get AlarmThresholds
 * @param {string} searchTerm
 * @param {QueryOptions} [queryOptions={}]
 * @return {Promise<AlarmThresholdListResponse>}
 */
const searchAlarmThreshold = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AlarmThreshold>): Promise<AlarmThreshold> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || {
      "tag.name.keyword": { order: "asc" }
    }
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          terms: { [`${key}.keyword`]: value }
        });
      } else if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post("alarmThresholds/search", searchBody);
};

/**
 * Get AlarmThreshold By ID
 *
 * @param alarmThresholdId: @ String
 *
 * @return {Promise<AlarmThresholdDocumentResponse>}
 */
export const getAlarmThresholdById = async (
  alarmThresholdId: string
): Promise<AlarmThresholdDocumentResponse> => {
  return acceleratorApi.get<AlarmThresholdDocumentResponse>(`alarmThresholds/${alarmThresholdId}`);
};

/**
 * Search AlarmThreshold By ID
 *
 * @param alarmThresholdId: @ String
 *
 * @return {Promise<AlarmThresholdDocumentResponse>}
 */
export const searchAlarmThresholdById = async (
  alarmThresholdId: string
): Promise<AlarmThresholdListResponse> => {
  const searchBody: SearchRequest = {
    from: 0,
    size: 1,
    query: {
      bool: {
        filter: [
          {
            term: { [`id.keyword`]: alarmThresholdId }
          }
        ]
      }
    }
  };
  return acceleratorApi.post("alarmThresholds/search", searchBody);
};

/**
 * Create AlarmThreshold
 *
 * @param alarmThresholds: @type Partial<AlarmThreshold>
 *
 * @return {Promise<AlarmThreshold>}
 */
export const createAlarmThreshold = async (
  alarmThresholds: Partial<AlarmThreshold>
): Promise<AlarmThresholdDocumentResponse> => {
  return acceleratorApi.post<Partial<AlarmThreshold>, AlarmThresholdDocumentResponse>(
    "alarmThresholds",
    alarmThresholds
  );
};

/**
 * Patch AlarmThreshold
 * @param previousValue: @type AlarmThreshold
 * @param nextValue: @type Partial<AlarmThreshold>
 *
 * @return {Promise<AlarmThresholdDocumentResponse>}
 */
export const patchAlarmThreshold = async (
  previousValue: AlarmThreshold,
  nextValue: Partial<AlarmThreshold>
) => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch<Operation[], AlarmThresholdDocumentResponse>(
    `alarmThresholds/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete AlarmThreshold
 *
 * @param alarmThresholdId: @ String
 *
 * @return {Promise<void>}
 */
export const deleteAlarmThreshold = async (alarmThresholdId: string): Promise<void> => {
  await acceleratorApi.delete(`alarmThresholds/${alarmThresholdId}`);
};

export const AlarmThresholdApi = {
  search: searchAlarmThreshold,
  getById: getAlarmThresholdById,
  searchById: searchAlarmThresholdById,
  create: createAlarmThreshold,
  update: patchAlarmThreshold,
  delete: deleteAlarmThreshold
};
