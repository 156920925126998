import { compare, Operation } from "fast-json-patch";

import { acceleratorApi } from ".";
import {
  AssetProperty,
  AssetPropertyDocumentResponse,
  AssetPropertyListResponse
} from "../../types/accelerator/asset-properties";
import { escapeCharacters } from "../../utils";
import { QueryDslQueryContainer, SearchRequest } from "@elastic/elasticsearch/lib/api/types";
import { SearchApiParams } from "../../types";

/**
 * Search Asset Properties
 *
 * @return {Promise<AssetPropertyListResponse>}
 */
export const searchPersonaAssetProperties = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetProperty>): Promise<AssetPropertyListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;
  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: [],
        filter: []
      }
    },
    sort: sortModel || {
      "assetProperty.name.keyword": { order: "asc" }
    }
  };

  if (searchTerm) {
    searchBody.query.bool.must = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.filter as QueryDslQueryContainer[]).push({
          term: { [`${key}.keyword`]: value }
        });
      }
    });
  }

  return await acceleratorApi.post<SearchRequest, AssetPropertyListResponse>(
    "personaAssetProperties/search",
    searchBody
  );
};

/**
 * Get Asset Properties By ID
 *
 * @param assetPropertyId: @type String
 *
 * @return {Promise<AssetPropertyDocumentResponse>}
 */
export const getAssetPropertyById = async (
  assetPropertyId: string
): Promise<AssetPropertyDocumentResponse> => {
  return acceleratorApi.get<AssetPropertyDocumentResponse>(`assetProperties/${assetPropertyId}`);
};

/**
 * Search Templates
 * @return {Promise<AssetPropertyListResponse>}
 */
export const searchAssetProperties = async ({
  searchTerm,
  filter,
  sortModel,
  options
}: SearchApiParams<AssetProperty>): Promise<AssetPropertyListResponse> => {
  const from = options?.from || 0;
  const size = options?.size || 20;

  const searchBody: SearchRequest = {
    from,
    size,
    query: {
      bool: {
        should: [],
        must: [],
        must_not: []
      }
    },
    sort: sortModel || {
      "name.keyword": { order: "asc" }
    }
  };

  if (searchTerm !== "") {
    searchBody.query = {
      multi_match: {
        query: escapeCharacters(searchTerm),
        type: "phrase_prefix",
        fields: ["*"]
      }
    };
  }

  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      if (value || value === null) {
        (searchBody.query.bool.must as QueryDslQueryContainer[]).push({
          match: { [`${key}.keyword`]: value as string }
        });
      }
    });
  }

  const response = await acceleratorApi.post<SearchRequest, AssetPropertyListResponse>(
    "assetProperties/search",
    searchBody
  );

  return response;
};

/**
 * Create AssetProperty
 *
 * @param assetProperty: @type Partial<AssetProperty>
 *
 * @return {Promise<AssetProperty>}
 */
export const createAssetProperty = async (
  assetProperty: Partial<AssetProperty>
): Promise<AssetPropertyDocumentResponse> => {
  return acceleratorApi.post<Partial<AssetProperty>, AssetPropertyDocumentResponse>(
    "assetProperties",
    assetProperty
  );
};

/**
 * Update AssetProperty
 *
 * @param assetPropertyUpdate: @type AssetProperty
 *
 * @return {Promise<AssetPropertyDocumentResponse>}
 */
export const updateAssetProperty = async (
  assetPropertyUpdate: Partial<AssetProperty>
): Promise<AssetPropertyDocumentResponse> => {
  return acceleratorApi.put<Partial<AssetProperty>, AssetPropertyDocumentResponse>(
    "assetProperties",
    assetPropertyUpdate
  );
};

/**
 * Patch AssetProperty
 *
 * @param assetPropertyId: @type String
 * @param previousValue: @type AssetProperty
 * @param nextValue: @type Partial<AssetProperty>
 *
 * @return {Promise<AssetPropertyDocumentResponse>}
 */
export const patchAssetProperty = async (
  assetPropertyId: string,
  previousValue: AssetProperty,
  nextValue: Partial<AssetProperty>
): Promise<AssetPropertyDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });

  return acceleratorApi.patch<Operation[], AssetPropertyDocumentResponse>(
    `assetProperties/${assetPropertyId}`,
    patchUpdates
  );
};

/**
 * Patch Persona AssetProperty
 *
 * @param previousValue: @type AssetProperty
 * @param nextValue: @type Partial<AssetProperty>
 *
 * @return {Promise<AssetPropertyDocumentResponse>}
 */
export const updatePersonaAssetProperty = async (
  previousValue: AssetProperty,
  nextValue: Partial<AssetProperty>
): Promise<AssetPropertyDocumentResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });

  return acceleratorApi.patch<Operation[], AssetPropertyDocumentResponse>(
    `personaAssetProperties/${previousValue.id}`,
    patchUpdates
  );
};

/**
 * Delete AssetProperty
 *
 * @param assetPropertyId: @type String
 *
 * @return {Promise<void>}
 */
export const deleteAssetProperty = async (assetPropertyId: string): Promise<void> => {
  await acceleratorApi.delete(`assetProperties/${assetPropertyId}`);
};

export const AssetPropertiesApi = {
  create: createAssetProperty,
  delete: deleteAssetProperty,
  searchPersonaAssetProperties,
  getById: getAssetPropertyById,
  patch: patchAssetProperty,
  search: searchAssetProperties,
  update: updateAssetProperty,
  updatePersonaAssetProperty
};
